/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://jeodzxougnbtxiwypxjthgknv4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ejfx6xvpfvbjhi2bokwmtitsmy",
    "aws_cognito_identity_pool_id": "us-east-1:5d846d1a-ca2f-494a-9578-a39a2d67db03",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fsH27JhqT",
    "aws_user_pools_web_client_id": "nfhh2nag0nft15g7tuh3nuihm",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cloudrender-app-bucket62737-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
